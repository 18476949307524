import styled from 'styled-components';

export const Content = styled.div`
  background: rgba(0, 0, 0, 0.9);
  border-radius: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  box-sizing: border-box;

  padding: 2rem;
  height: 100%;
`;

interface WrapperProp {
  img: string;
}

export const Wrapper = styled.div<WrapperProp>`
  background: url(${({ img }): string => img});
  background-position: center center;
  background-size: cover;
  border-radius: 40px;

  margin-bottom: 2rem;

  height: 360px;
  width: 640px;

  ${Content} {
    opacity: 0;
    transition: opacity 250ms ease-out;
  }

  &:hover ${Content} {
    opacity: 1;
    transition: opacity 250ms ease-out;
  }

  .header {
    font-size: 1.5rem;
  }
  .body {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media only screen and (min-width: 1024px) {
    height: 405px;
    width: 720px;

    .header {
      font-size: 1.5rem;
    }
    .body {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    height: 652.5px;
    width: 1160px;

    .header {
      font-size: 2.1rem;
    }
    .body {
      font-size: 1rem;
    }
  }
`;
