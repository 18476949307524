import React from 'react';
import styled from 'styled-components';
import { Typography } from 'amphitheatre';

import OrbitOz from './orbitoz.png';
import SperoSpace from './sperospace.jpg';
import SpiralBlue from './spiralblue.jpg';
import Dandelions from './dandelions.jpg';
import SpaceIndustries from './SpaceIndustries.png';
import Metakosmos from './metakosmos.png';
import Ant61 from './ant61.png';

interface Props {
  img: string;
}

const MobileWrapper = styled.div<Props>`
  background: url(${({ img }): string => img});
  background-position: center center;
  background-size: cover;
  border-radius: 20px;

  display: flex;
  justify-content: center;

  margin: auto;
  margin-bottom: 2rem;

  height: 196px;
  width: 100%;

  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 16px 8px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 16px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 16px 8px rgba(0, 0, 0, 0.25);
`;

const MobileContainer = styled.div`
  display: block;
  padding: 0.5rem 1rem;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const { Header } = Typography;

const MobileCompanies: React.FunctionComponent = () => {
  return (
    <MobileContainer>
      <Header level={3}>OrbitOz</Header>
      <a href="http://orbitoz.com/" rel="noopener noreferrer" target="_blank">
        <MobileWrapper img={OrbitOz} />
      </a>

      <Header level={3}>Sperospace</Header>
      <a
        href="http://sperospace.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MobileWrapper img={SperoSpace} />
      </a>

      <Header level={3}>Spiral Blue</Header>
      <a
        href="https://www.spiralblue.space/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MobileWrapper img={SpiralBlue} />
      </a>

      <Header level={3}>Dandelions.Cloud</Header>
      <a
        href="https://www.dandelions.cloud/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MobileWrapper img={Dandelions} />
      </a>

      <Header level={3}>Space Industries</Header>
      <a
        href="https://spaceindustries.com.au/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MobileWrapper img={SpaceIndustries} />
      </a>

      <Header level={3}>Metakosmos</Header>
      <a
        href="https://metakosmos.com.au/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MobileWrapper img={Metakosmos} />
      </a>

      <Header level={3}>ANT61 Robotics</Header>
      <a href="https://ant61.com/" rel="noopener noreferrer" target="_blank">
        <MobileWrapper img={Ant61} />
      </a>
    </MobileContainer>
  );
};

export default MobileCompanies;
