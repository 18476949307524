import React from 'react';
import { Typography } from 'amphitheatre';
import { Content, Wrapper } from './Company.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  img: string;
  name: string;
}

const { Header, Paragraph } = Typography;

const Company: React.FunctionComponent<Props> = ({
  img,
  name,
  children,
  className,
}: Props) => {
  return (
    <Wrapper className={`company ${className || ''}`} img={img}>
      <Content>
        <Header className="header">{name}</Header>
        <Paragraph className="body">{children}</Paragraph>
      </Content>
    </Wrapper>
  );
};

export default Company;
