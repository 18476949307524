import styled from 'styled-components';

export const DotImage = styled.img`
  border: 4px solid ${({ theme }): string => theme.primary700};
  border-radius: 100%;

  width: 48px;
  height: 48px;
`;

export const DotWrapper = styled.div`
  bottom: 0px;
  li {
    height: auto;
    width: auto;
  }
  .slick-active ${DotImage} {
    border: 4px solid ${({ theme }): string => theme.primary400};
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 16px 8px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 16px 8px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 16px 8px rgba(0, 0, 0, 0.25);
  }
`;

export const LeftArrow = styled.div`
  &::before {
    display: none;
  }

  color: white;
  font-size: 3rem;

  height: auto;
  width: auto;

  left: 10px;

  &:hover {
    color: ${({ theme }): string => theme.grey800};
  }
`;

export const RightArrow = styled.div`
  &::before {
    display: none;
  }

  color: white;
  font-size: 3rem;

  height: auto;
  width: auto;

  right: 10px;

  &:hover {
    color: ${({ theme }): string => theme.grey800};
  }
`;

export const Wrapper = styled.div`
  display: none;

  .slick-slide .company {
    transform: scale(0.9);
    transition: all 250ms;
  }

  .slick-center .company {
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 16px 8px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 16px 8px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 16px 8px rgba(0, 0, 0, 0.25);

    transform: scale(1);
    transition: all 250ms;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;
