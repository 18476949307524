import { Button } from 'amphitheatre';
import React from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Slider from 'react-slick';

import LogoDandelions from '../../images/LogoDandelion.png';
import LogoMetakosmos from '../../images/LogoMetakosmos.png';
import LogoOrbitOz from '../../images/LogoOrbitOz.png';
import LogoSpaceIndustries from '../../images/LogoSpaceIndustries.png';
import LogoSperoSpace from '../../images/LogoSperoSpace.png';
import LogoSpiralBlue from '../../images/LogoSpiralBlue.png';
import LogoAnt61 from '../../images/LogoANT61.png';
import {
  DotImage,
  DotWrapper,
  LeftArrow,
  RightArrow,
  Wrapper,
} from './Companies.styled';
import Company from './Company';
import Dandelions from './dandelions.jpg';
import Metakosmos from './metakosmos.png';
import OrbitOz from './orbitoz.png';
import SpaceIndustries from './SpaceIndustries.png';
import SperoSpace from './sperospace.jpg';
import SpiralBlue from './spiralblue.jpg';
import Ant61 from './ant61.png';

const logos: Map<number, string> = new Map();
logos.set(0, LogoSpiralBlue);
logos.set(1, LogoOrbitOz);
logos.set(2, LogoSperoSpace);
logos.set(3, LogoDandelions);
logos.set(4, LogoSpaceIndustries);
logos.set(5, LogoMetakosmos);
logos.set(6, LogoAnt61);

const Companies: React.FunctionComponent = () => {
  const companies = [
    <Company img={SpiralBlue} name="Spiral Blue">
      Spiral Blue is a Sydney-based SME focused on building the next generation
      of Earth observation services with artificial intelligence and Space Edge
      Computing. Spiral Blue technology has applications in defence, city
      planning, utilities, and other industries. Founded in 2018, the company
      has recently launched its first Space Edge Zero prototypes to orbit, and
      is now continuing to gather further in-orbit performance data. Spiral Blue
      is a member of the Wolfpack Space Hub.
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <a
          href="https://www.spiralblue.space/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>Visit Spiral Blue</Button>
        </a>
      </div>
    </Company>,
    <Company img={OrbitOz} name="OrbitOz">
      Australia's best (and most relaxed!) community of space professionals and
      enthusiasts since 2014. OrbitOz has a monthly event where speakers from
      the space community share their experiences. Join our growing online
      network by visiting the following links below!
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <a href="http://orbitoz.com/" rel="noopener noreferrer" target="_blank">
          <Button>Visit OrbitOz</Button>
        </a>
      </div>
    </Company>,
    <Company img={SperoSpace} name="Sperospace">
      At Sperospace, we are leveraging our experience in robotics to
      industrialise space by developing robotic systems for in-space servicing,
      assembly, and in situ resource utilisation. This has the potential to
      reduce costs and improve the capability of space infrastructure which will
      no longer be constrained by how much mass or volume can be carried on a
      rocket. Sperospace is designing a 6 degree of freedom (6DoF) flat-packed
      robotic arm with switchable end effectors to assemble and service large
      structures in space, and eventually on the moon. We hope to connect with
      customers interested in robotic devices for satellite servicing and are
      also developing modular, flat packed satellite components with our
      commercial and research partners.
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <a
          href="http://sperospace.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>Visit Sperospace</Button>
        </a>
      </div>
    </Company>,
    <Company img={Dandelions} name="Dandelions.Cloud">
      Your company needs a sensor grid, but how do you make this happen. Simple
      - focus on the exact individual sensor package your clients care about.
      The rest - IT, power and communications - let Dandelions handle it. We
      offer standard packages, (a) fixed, (b) vehicle, or (c) air deployable,
      with room for your sensor package; and everything else just works.Our
      engineers are focused on making this happen, working with clients across
      Space Logistics, Emergency Services, and more generally in On-Demand IOT.
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <a
          href="https://www.dandelions.cloud/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>Visit Dandelions.Cloud</Button>
        </a>
      </div>
    </Company>,
    <Company img={SpaceIndustries} name="Space Industries">
      Space Industries are a rapidly growing SME focused on producing critical
      resources from the Moon, this includes water for use in Space and helium-3
      for use on Earth. Space Industries have identified several areas where its
      Lunar technology can be technology transferred to solve problems within
      the mining and other industries here on Earth.
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <a
          href="https://spaceindustries.com.au/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>Visit Space Industries</Button>
        </a>
      </div>
    </Company>,
    <Company img={Metakosmos} name="Metakosmos">
      Metakosmos is a human spaceflight &amp; next generation spacesuit
      technology company. With the mission of "Spaceflight for all", the company
      aims to democratise the access of spacesuits &amp; human spaceflight
      experience.
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <a
          href="https://metakosmos.com.au/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>Visit Metakosmos</Button>
        </a>
      </div>
    </Company>,
    <Company img={Ant61} name="ANT61 Robotics">
      ANT61 develops autonomous robots to avoid putting human lives at risk. We
      use AI-based control systems that enable our robots to perform various
      installation tasks in unpredictable environments where the remote control
      is impossible.
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <a href="https://ant61.com/" rel="noopener noreferrer" target="_blank">
          <Button>Visit ANT61 Robotics</Button>
        </a>
      </div>
    </Company>,
  ];

  // Assign key and className
  const elements = companies.map((company, index) =>
    React.cloneElement(company, {
      key: index,
    }),
  );

  const settings = {
    arrows: false,
    centerMode: true,
    dots: true,
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,

    prevArrow: (
      <LeftArrow>
        <BsChevronLeft />
      </LeftArrow>
    ),
    nextArrow: (
      <RightArrow>
        <BsChevronRight />
      </RightArrow>
    ),

    appendDots: (dots: JSX.Element): JSX.Element => (
      <DotWrapper>{dots}</DotWrapper>
    ),
    customPaging: (i: number): JSX.Element => <DotImage src={logos.get(i)} />,
  };

  return (
    <Wrapper>
      <Slider {...settings}>{elements}</Slider>
    </Wrapper>
  );
};

export default Companies;
