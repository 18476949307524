import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from 'amphitheatre';
import Layout from '../components/Layout';

import * as Styled from '../pages-styles/shared.styled';
import {
  BackgroundWrapper,
  BLCircle,
  TLCircle,
  BRBigCircle,
  BRSmallCircle,
} from '../components/Background';
import Companies from '../components/Companies';
import MobileCompanies from '../components/Companies/Companies.mobile';

const { Header, Paragraph } = Typography;

const Startups: React.FunctionComponent = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wolfpack - Startups</title>
      </Helmet>
      <BackgroundWrapper>
        <BLCircle />
        <TLCircle />
        <BRBigCircle />
        <BRSmallCircle />
      </BackgroundWrapper>
      <Styled.Container>
        <Header className="page-header">STARTUPS</Header>
        <Paragraph>
          Below are the current Wolfpack Space Hub occupants.
        </Paragraph>
      </Styled.Container>
      <Companies />
      <MobileCompanies />
    </Layout>
  );
};

export default Startups;
